import React, { useEffect, useState } from "react"
import Image from "gatsby-image/withIEPolyfill"
import { motion } from "framer-motion"
import css from "@styled-system/css"
import { Link as ScrollLink } from "react-scroll"

import { Box, Flex, Heading, Shape, Wrapper } from "components"

const CONTENT_START_ID = "intro"

// Hero section text, containing a background, a logo image and a title card.
const BAR_DURATION = 0.8
const IMAGE_DURATION = 2.6
const TEXT_DURATION = 0.6
const CONTENT_DELAY = 0.5
const DELAY = 0

const EASE_OUT_QUINT = [0.23, 1, 0.32, 1]

const imageVariants = {
  initial: {
    scale: 1.05,
  },
  enter: {
    scale: 1,
    transition: {
      scale: {
        duration: IMAGE_DURATION,
        delay: DELAY,
        ease: EASE_OUT_QUINT,
      },
    },
  },
}

const scaleXVariants = {
  initial: {
    scaleX: 0,
  },
  enter: {
    scaleX: 1,
    transition: {
      scaleX: {
        duration: BAR_DURATION,
        delay: DELAY,
        ease: EASE_OUT_QUINT,
      },
    },
  },
}

const scaleYVariants = {
  initial: {
    scaleY: 0,
  },
  enter: {
    scaleY: 1,
    transition: {
      scaleY: {
        duration: BAR_DURATION,
        delay: DELAY,
        ease: EASE_OUT_QUINT,
      },
    },
  },
}

const contentVariants = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    transition: {
      opacity: {
        duration: TEXT_DURATION,
        delay: CONTENT_DELAY,
      },
    },
  },
}

function HeroSection({ data }) {
  const [isHovering, setIsHovering] = useState(false)
  const [activeSection, setActiveSection] = useState(undefined)

  useEffect(() => {
    // Set static viewport height unit on initial load
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty("--vh", `${vh}px`)
    //select an image from the sections to set as the default view
    setActiveSection(Math.floor(Math.random() * data.sections.length))
  }, [data])

  return (
    <>
      {activeSection !== undefined && (
        <Flex
          justifyContent="center"
          height={[
            "calc(var(--vh, 1vh) * 100)", // We use CSS variables for this to keep vertical height units static on iOS
            "100vh",
          ]}
        >
          <Flex
            position="relative"
            width="100%"
            height="100%"
            bg="wash"
            overflow="hidden"
          >
            {/* IMAGE */}
            {data.sections &&
              data.sections[activeSection] &&
              data.sections[activeSection].image &&
              data.sections[activeSection].image.fluid && (
                <motion.div
                  variants={imageVariants}
                  initial="initial"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <Image
                    fluid={data.sections[activeSection].image.fluid}
                    alt={data.sections[activeSection].image.alt}
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                    objectFit="cover"
                    objectPosition="center"
                  />
                </motion.div>
              )}
            <Wrapper
              position="relative"
              pt={[128, 224]}
              px={["layout.4", "layout.6", null, null, "layout.4"]}
            >
              <motion.div variants={contentVariants} initial="initial">
                <Flex width="100%" flexWrap="wrap">
                  {/* HEADING AND ARROW */}
                  <Box width={["100%", null, "auto"]} flex={[0, null, 1]}>
                    <Heading
                      as="h1"
                      size={900}
                      maxWidth={512}
                      children={
                        data.sections &&
                        data.sections[activeSection] &&
                        data.sections[activeSection].title &&
                        data.sections[activeSection].title.text
                      }
                    />
                  </Box>
                  <Box
                    width={["100%", null, 128]}
                    ml={[0, null, "auto"]}
                    mt={["layout.4", null, 0]}
                  >
                    <ScrollLink
                      className={isHovering === true ? "isHovering" : ""}
                      smooth={true}
                      to={CONTENT_START_ID}
                      css={css({
                        display: "block",
                        cursor: "pointer",
                        // Move icon styles
                        ".octagon__icon--before": {
                          transform: "translateY(0)",
                          transition: "transform 0ms linear",
                        },
                        ".octagon__icon--after": {
                          transform: "translateY(-128px)",
                          transition: "transform 0ms linear",
                        },
                        "&.isHovering": {
                          ".octagon__icon--before": {
                            transform: "translateY(128px)",
                            transition: "transform 400ms ease-out",
                          },
                          ".octagon__icon--after": {
                            transform: "translateY(0)",
                            transition: "transform 400ms ease-out",
                          },
                        },
                      })}
                      onMouseEnter={() => setIsHovering(true)}
                      onMouseLeave={() => setIsHovering(false)}
                    >
                      <Shape size={[48, 64, 128]} bg="background">
                        <Flex
                          position="absolute"
                          top="50%"
                          left="50%"
                          justifyContent="center"
                          alignItems="center"
                          width={[48, 64, 128]}
                          height={[48, 64, 128]}
                          overflow="hidden"
                          css={{ transform: "translate(-50%, -50%)" }}
                        >
                          <Box
                            as="svg"
                            className="octagon__icon--before"
                            width={[
                              (36 / 67) * 24,
                              (36 / 67) * 32,
                              (36 / 67) * 64,
                            ]}
                            height={[24, 32, 64]}
                            viewBox="0 0 36 67"
                            fill="none"
                            color="primary"
                          >
                            <title>Arrow Down</title>
                            <path
                              stroke="currentColor"
                              strokeWidth={4}
                              d="M18 0v64M2 48l16 16 16-16"
                            />
                          </Box>
                        </Flex>
                        <Flex
                          position="absolute"
                          top="50%"
                          left="50%"
                          justifyContent="center"
                          alignItems="center"
                          width={[48, 64, 128]}
                          height={[48, 64, 128]}
                          overflow="hidden"
                          css={{ transform: "translate(-50%, -50%)" }}
                        >
                          <Box
                            as="svg"
                            className="octagon__icon--after"
                            width={[
                              (36 / 67) * 24,
                              (36 / 67) * 32,
                              (36 / 67) * 64,
                            ]}
                            height={[24, 32, 64]}
                            viewBox="0 0 36 67"
                            fill="none"
                            color="primary"
                          >
                            <title>Arrow Down</title>
                            <path
                              stroke="currentColor"
                              strokeWidth={4}
                              d="M18 0v64M2 48l16 16 16-16"
                            />
                          </Box>
                        </Flex>
                      </Shape>
                    </ScrollLink>
                  </Box>
                </Flex>
              </motion.div>
            </Wrapper>

            {/* TOP */}
            <motion.div
              variants={scaleYVariants}
              initial="initial"
              css={css({
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: [16, 32],
                bg: "background",
                transformOrigin: "top",
              })}
            />
            {/* LEFT */}
            <motion.div
              variants={scaleXVariants}
              initial="initial"
              css={css({
                position: "absolute",
                top: 0,
                left: 0,
                width: [16, 32],
                height: "100%",
                bg: "background",
                transformOrigin: "left",
              })}
            />
            {/* RIGHT */}
            <motion.div
              variants={scaleXVariants}
              initial="initial"
              css={css({
                position: "absolute",
                top: 0,
                right: 0,
                width: [16, 32],
                height: "100%",
                bg: "background",
                transformOrigin: "right",
              })}
            />
            {/* BOTTOM */}
            <motion.div
              variants={scaleYVariants}
              initial="initial"
              css={css({
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                height: [16, 32],
                bg: "background",
                transformOrigin: "bottom",
              })}
            />
          </Flex>
        </Flex>
      )}
    </>
  )
}

export default HeroSection
