import React from "react"
import { FadeWrapper, RichText, Wrapper } from "components"

// Simple component, for holding a single block of centered text with a blank background.

const IntroSection = ({ data }) => (
  <>
    {data.description && (
      <Wrapper
        id="intro"
        maxWidth={1080 / 1.5}
        pt={["layout.6", "layout.7"]}
        pb={["layout.7", "layout.8"]}
      >
        <FadeWrapper>
          <RichText
            size={600}
            content={{ html: data.description }}
            textAlign="center"
          />
        </FadeWrapper>
      </Wrapper>
    )}
  </>
)

export default IntroSection
