import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { GridRow, SEO } from "components"

import HeroSection from "./_heroSection"
import IntroSection from "./_introSection"

function IndexPage() {
  const { page } = useStaticQuery(HOME_QUERY)
  return (
    <>
      <SEO
        title={page.data.seo_title && page.data.seo_title.text}
        description={
          page.data.seo_description && page.data.seo_description.text
        }
        imageOpenGraph={page.data.seo_image && page.data.seo_image.url}
        imageTwitter={page.data.seo_image && page.data.seo_image.url}
        imageAlt={page.data.seo_image && page.data.seo_image.alt}
      />

      {/* Hero section with image and text. Header menu overlaps the Hero Image. */}
      <HeroSection data={{ sections: page.data.hero_sections }} />

      {/* Text block immediately beneath title section. Simple centered text block. */}
      <IntroSection
        data={{
          description: page.data.intro_text.html,
        }}
      />

      {page.data.body1.map((slice, index) => (
        <GridRow
          data={{
            heading: slice.primary.heading.text,
            body: slice.primary.grid_text.html,
            button: slice.primary.button_label,
            buttonLink:
              slice.primary.button_link && slice.primary.button_link.url,
            imageOne: slice.primary.primary_image,
            imageTwo: slice.primary.secondary_image,
            index: index,
          }}
          key={"slice" + index}
        />
      ))}
    </>
  )
}

const HOME_QUERY = graphql`
  {
    page: prismicLandingPage {
      data {
        seo_title {
          text
        }
        seo_description {
          text
        }
        seo_image {
          url
          alt
        }
        hero_sections {
          title {
            text
          }
          image {
            fluid(maxWidth: 1600) {
              ...GatsbyPrismicImageFluid
            }
            alt
          }
        }
        intro_text {
          html
        }
        body1 {
          ... on PrismicLandingPageBody1GridRow {
            id
            primary {
              heading {
                text
              }
              grid_text {
                html
              }
              button_label
              button_link {
                url
              }
              primary_image {
                fluid(maxWidth: 512) {
                  ...GatsbyPrismicImageFluid
                }
                alt
              }
              secondary_image {
                fluid(maxWidth: 512) {
                  ...GatsbyPrismicImageFluid
                }
                alt
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
